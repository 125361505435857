.lkjDVC {
  width: 170px !important;
  height: 200px !important;
  flex-direction: column !important;
  justify-content: center !important;
  border: 2px dashed grey !important;
  max-width: unset !important;
  min-width: unset !important;
  /* -webkit-flex-grow: unset; */
}

.dmajdp {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  -webkit-flex-grow: unset !important;
}

.dmajdp>span {
  text-align: center !important;
}

.lkjDVC svg path {
  fill: grey !important;
  stroke: grey !important;
}

.imageUplaodlabel2 {
  position: absolute !important;
  top: 0 !important;
  opacity: 0 !important;
  width: 100% !important;
}

.FileUploader-banner {
  min-width: unset !important;
  max-width: unset !important;
  height: 202px !important;
  z-index: 99999999999999 !important;
}

.MuiBox-root {
  /* object-fit: contain !important; */
}