.Details {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  overflow: unset;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  border-radius: 0.375rem;
  flex-direction: column;
  background-clip: initial;
  background-color: #ffffff;
  /* padding: 20px; */
}
.Details .Header,
.Details .body {
  padding: 20px;
}
.Body {
}
.Details .Header {
  border-bottom: 1px solid #525f7f;
}

.Body .Inner_Body,
.Parent {
  border-bottom: 1px solid #525f7f;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.Body .Parent {
  min-height: 200px;
}
.Body .Card {
  /* width: 50%; */
  width: 73%;
  overflow: auto;
}
.Body .Information,
.Body .TotalPrice {
  position: relative;
  width: 25%;
  background: ghostwhite;
  padding: 15px;
  border-radius: 10px;
  min-height: 160px;
}
.TotalPrice span.Heading {
  color: #000;
  font-size: 16px;
}
.Info {
  margin-bottom: 6px;
}
.TotalPrice .Info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}
.Body span.InfoBody {
  margin-left: 6px;
  font-size: 16px;
  color: #000000;
}
.Body .Tracking {
  padding: 13px;
  width: 20%;
  background: ghostwhite;
  border-radius: 10px;
}
.Body .Tracking_order {
  overflow: auto;
  max-height: 300px;
}
.Body ::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
.Body ::-webkit-scrollbar-track {
  background: #f8f8ff;
}

/* Handle */
.Body ::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.Body ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.Body .Tracking_Card {
  padding: 10px 10px;
  background: white;
  margin: 10px;
  border-radius: 10px;
}
.Info.InfoTotal {
  margin-top: 24px;
  position: absolute;
  width: 93%;
  bottom: 0px;
}
.InfoTotal span.Heading {
  font-size: 18px;
}
.Body .Product {
  width: 50%;
  padding: 10px;
  background: ghostwhite;
  border-radius: 10px;
}
.Body .Cards_Total {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  background: white;
  border-radius: 10px;
  margin-bottom: 10px;
}

.Body .ImgCards {
  display: flex;
}
.Body .Cards_Inner {
  margin-right: 4px;
  position: relative;
  width: 100px;
  /* height: 100px; */
  text-align: center;
}
.Body .Img_parent img {
  width: 100%;
  height: 100px;
  border-radius: 100%;
  margin-bottom: 10px;
}
.Body .Child {
  display: flex;
}
.Header .Btn_parent {
  right: 20px;
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
}
.Header .btn {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header .btn svg {
  font-size: 18px;
  margin-right: 9px;
}
.Header .Confirm svg {
  color: green;
}
.Header .Cancel svg {
  color: red;
  font-size: 22px !important;
}
