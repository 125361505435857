.site-tag-plus {
  background: #fff;
  border-style: dashed;
}

.edit-tag {
  user-select: none;
}

.tag-input {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
  border-color: blue;
}

.colorImageTab .MuiTabs-flexContainer {
  display: flex;
  overflow: auto;
  padding-bottom: 12px;
}

.colorImageTab button {
  padding: 10px;
  height: auto;
  flex: unset;
}

.ImagesDiv {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.ImagesDiv .imageUplaodlabel {
  border: 2px dashed gray;

  width: 170px;
  height: 200px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 20px;
  position: relative;
}

.ImagesDiv .imageUplaodlabel input {
  opacity: 0;
  transform: scale(0);
  position: absolute;
}

.ImagesDiv .imageUplaodlabel p {
  margin: 0px;
}

.ImagesDiv .imageUplaodlabel svg {
  font-size: 40px;
}

.ImagesDiv .imageUplaodlabel img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.ImagesDiv .cancelImage {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: white;
  border-radius: 50px;
  cursor: pointer;
}

.ImagesDiv .cancelImage .cancelImagIcon {
  font-size: 24px;
  border-radius: 50px;
}